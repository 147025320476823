import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { fetchPayGroupsWithLocations } from 'store/events';
import { RootState } from '..';
import { fetchAllBatches, fetchBatch } from './batches';
import {
  fetchEmployeeConfigs,
  fetchEmployee,
  fetchEmployeeDetailsEWADraws,
  fetchEmployeeDetailsTPODraws,
  fetchEmployeesWithConfigsLocationsAndGroups,
  fetchEmploymentPayrollPeriods,
  searchEmployees,
  createDeductionBatches,
  assignEmployeesToEmployment,
  saveZendeskTicket,
  fetchEmployeesOffers,
  fetchEmployeeOfferById,
  fetchFilteredEmployees,
  setPrimaryAssignmentForEmployee,
  fetchWorkerEmploymentLinks,
  createACF,
  createBadDebt,
  generateDirectDepositReportReport,
  editEmployeeDetailsProfile,
  editEmployeeDetailsOnDemandPay,
  fetchEmployeesTPOOffers,
  fetchDirectDepositEnrollmentByWorkerId,
  fetchEmploymentPayRates,
  editEmploymentPayRates,
  declineDraw,
  voidDraw,
} from './employees';
import {
  fetchLocation,
  fetchLocations,
  createLocation,
  editLocation,
  patchLocation,
  importLocations,
  fetchLocationConfigs,
  generateLocationsConfigReport,
  editLocationConfigs,
  saveLocationPaycardConfig,
} from './locations';
import {
  // fetchAllOrganizations,
  fetchDefaultConfig,
  fetchOrganizationByIdWithConfigAndSectionStatuses,
  fetchOrganizationConfigs,
  createOrganization,
  createOrganizationSectionStatuses,
  editOrganization,
  editOrganizationConfigs,
  editOrganizationEWASettings,
  editOrganizationSectionStatuses,
  editOrganizationTPOSettings,
  saveOrganizationTPOSettingsBanking,
  editOrganizationDirectDepositSettings,
  saveOrganizationDirectDepositSettingsBanking,
  editOrganizationTipSourceConfiguration,
} from './organizations';
import {
  assignLocationsToPayGroup,
  createPayGroup,
  editPayGroup,
  fetchPayGroup,
  fetchPayGroups,
  fetchPayrollPeriods,
  generatePayPeriods,
  savePayGroupPayPeriods,
  closePayPeriod,
  deletePayPeriod,
  updatePayrollPeriod,
  fetchPayGroupWithLocations,
  patchPayGroup,
} from './payGroups';
import {
  createRepayment,
  createTPORepaymentAllocation,
  fetchRepayments,
  updateRepayment,
} from './repayments';
import {
  fetchWorker,
  fetchWorkers,
  searchWorkers,
  setWorkerProfileAttributesTrusted,
} from './workers';
import {
  createBatchAllocations,
  createDrawAllocations,
  createEmploymentAllocations,
  fetchBatchAllocations,
  fetchBatchEmploymentDrawAllocations,
  fetchBatchEmployments,
} from './paymentAllocation';
import {
  fetchWorkerAccountBalance,
  fetchWorkerAccountExternal,
  fetchWorkerAccountWithBalance,
} from './workerAccount';
import {
  fetchBalance,
  fetchDashBalance,
} from './masterAccount';
import {
  fetchMasterAccountTransactionsOnLoadMore,
  fetchWorkerAccountTransactionsOnLoadMore,
  fetchMasterAccountTransactions,
  fetchWorkerAccountTransactions,
  createTransaction,
  fetchBankFiles,
  recoverFunds,
  resendMoneyFromMFA,
  fetchDashAccountTransactions,
} from './transactions';
import {
  saveAdministrator,
  fetchAllAdministrators,
  fetchAdministratorById,
  updateAdminRoles,
} from './administrators';
import {
  downloadLatestTipFile,
  fetchTipsFiles,
} from './tna';
import {
  fetchTPOPayrollFile,
  fetchTippedEmployeeBalanceOffer,
  fetchTippedEmployeeBalanceOfferById,
  fetchTippedEmployeesByPayPeriod,
  fetchTippedEmployeeTPOPayments,
  importTipsFile,
  initiateTPOPayments,
  fetchTPODailyFile,
} from './tpo';
import { fetchUniversalCalendarDailyShifts } from './universalCalendar';
import {
  fetchOutstandingBatchesReport,
  fetchOutstandingRepaymentsReport,
  fetchTreasurySummary,
} from './reports';
import {
  fetchOrganizationPaycardSettings,
  fetchPaycardAccountDetails,
  orderNewCards,
  saveOrganizationPaycardSettings,
} from './paycards';
import {
  deleteSourceAccount,
  editRepayment,
  editSourceAccount,
  fetchOrganizationRepaymentRequests,
  fetchOrganizationRepaymentRequestsOnLoadMore,
  processRepaymentRequests,
  saveOrganizationSettings,
  saveSourceAccount,
} from './repaymentsBankDetails';
import { fetchPayPeriodsWithActions } from './dashboard';

export interface AppError {
  message?: string,
  messageKey?: string,
  errorTitle?: string,
  errorTitleKey?: string,
  stackTrace?: string,
  type?: string
}

export const initialState: AppError = {
  message: undefined,
  messageKey: undefined,
  errorTitle: undefined,
  errorTitleKey: undefined,
  stackTrace: undefined,
  type: undefined,
};

export const handleError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 403:
      state.errorTitleKey = 'errors:status.403.title';
      state.messageKey = 'errors:status.403.message';
      break;

    case 401:
      state.errorTitleKey = 'errors:errorCode.AUTHENTICATION_ERROR';
      state.messageKey = 'errors:errorCode.AUTHENTICATION_ERROR';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = code
        ? `errors:errorCode.${code}`
        : 'errors:somethingWentWrong';
      break;
  }
};

export const updateRepaymentError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:errorCode.VALIDATION_ERROR';
      state.messageKey = 'errors:updateRepayment';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = `errors:errorCode.${code}`;
      break;
  }
};

export const sourceAccountsError = (
  state: AppError,
  error: Partial<PayloadAction<any> & { error: any }>,
) => {
  const { payload, error: responseError } = error;

  const { response } = payload || {};

  const { data, status } = response || {};

  const { messages, code } = data?.error || {};

  switch (status) {
    case 403:
      state.errorTitleKey = 'errors:status.403.title';
      state.messageKey = 'errors:status.403.message';
      break;

    case 401:
      state.errorTitleKey = 'errors:errorCode.AUTHENTICATION_ERROR';
      state.messageKey = 'errors:errorCode.AUTHENTICATION_ERROR';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = code
        ? `errors:errorCode.${code}`
        : 'errors:unableToPerformAction';

      break;
  }
};

export const fetchPayPeriodsWithActionsError = (
  state: AppError,
  error: Partial<PayloadAction<any> & { error: any }>,
) => {
  const { payload } = error;

  const { response } = payload || {};

  const { status } = response || {};

  switch (status) {
    case 403:
      state.errorTitleKey = 'errors:status.403.title';
      state.messageKey = 'errors:status.403.message';
      break;

    case 401:
      state.errorTitleKey = 'errors:errorCode.AUTHENTICATION_ERROR';
      state.messageKey = 'errors:errorCode.AUTHENTICATION_ERROR';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:unableToLoadPayPeriodData';
      break;
  }
};

export const importLocationsError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:errorCode.FILE_STRUCTURE_INVALID';
      state.messageKey = 'errors:importLocations';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = `errors:errorCode.${code}`;
      break;
  }
};

export const initiateTPOPaymentError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:errorCode.TPO_PAYMENT_IN_PROGRESS';
      state.messageKey = 'errors:initiateTPOPayment.paymentInProgress';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = `errors:errorCode.${code}`;
      break;
  }
};

export const saveOrganizationTPOSettingsEWAError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:errorCode.VALIDATION_ERROR';
      state.messageKey = 'errors:saveOrganizationSettingsEwaFailed';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = `errors:errorCode.${code}`;
      break;
  }
};

export const saveOrganizationDirectDepositSettingsEWAError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'Something Went Wrong';
      state.messageKey = 'errors:saveOrganizationSettingsEwaFailed';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = `errors:errorCode.${code}`;
      break;
  }
};

export const saveOrganizationTPOSettingsBankingError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:errorCode.VALIDATION_ERROR';
      state.messageKey = 'errors:saveOrganizationSettingsBankingFailed';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = `errors:errorCode.${code}`;
      break;
  }
};

export const saveOrganizationDirectDepositSettingsBankingError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
    code,
  } = data?.error || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'Something Went Wrong';
      state.messageKey = 'errors:saveOrganizationSettingsBankingFailed';
      break;

    default:
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'Something Went Wrong!';
      state.errorTitleKey = `errors:errorCode.${code}`;
      break;
  }
};

export const importTipsFileError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:importTipsFile.invalidFormat';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:importTipsFile.errors';
      break;
  }
};

export const generateDirectDepositReportError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:generateFile.failed';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:generateFile.failed';
      break;
  }
};

export const fetchEmployeesTPOOffersError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:generateEmployeeTPOOffer.failed';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:generateEmployeeTPOOffer.failed';
      break;
  }
};

export const fetchDirectDepositEnrollmentByWorkerIdError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:fetchDirectDepositEnrollmentByWorkerId.failed';
      break;

    case 404:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:fetchDirectDepositEnrollmentByWorkerId.notFound';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:fetchDirectDepositEnrollmentByWorkerId.failed';
      break;
  }
};

export const downloadTipsDailyFileError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:downloadLatestTipFile.failed';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:downloadLatestTipFile.failed';
      break;
  }
};

export const fetchUniversalCalendarDailyShiftsError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:fetchUniversalCalendarData.failed';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:fetchUniversalCalendarData.failed';
      break;
  }
};

export const fetchTreasurySummaryError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 400:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:loadFinancialInformation.failed';
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:loadFinancialInformation.failed';
      break;
  }
};

export const fetchOrganizationPaycardSettingsError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 404:
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:requestFailed';
      break;
  }
};

export const orderNewCardsError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 404:
      break;

    default:
      state.errorTitleKey = 'errors:unableToOrderCards';
      state.messageKey = 'errors:requestFailed';
      break;
  }
};

export const processPaymentRequestsError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    status,
  } = response || {};

  switch (status) {
    case 401:
      break;

    default:
      state.errorTitleKey = 'errors:somethingWentWrong';
      state.messageKey = 'errors:processRepaymentRequests.unableToProcess';
      break;
  }
};

export const saveOrganizationSettingsError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
  } = data?.error || {};

  switch (status) {
    default:
      state.errorTitleKey = 'errors:unableToSaveData';
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'errors:somethingWentWrong';
      break;
  }
};

export const declineDrawError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
  } = data?.error || {};

  switch (status) {
    default:
      state.errorTitleKey = 'errors:unableToDecline';
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'errors:somethingWentWrong';
      break;
  }
};

export const voidDrawError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
  } = data?.error || {};

  switch (status) {
    default:
      state.errorTitleKey = 'errors:unableToVoid';
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'errors:somethingWentWrong';
      break;
  }
};

export const resendMoneyFromMFAError = (state: AppError, error: Partial<PayloadAction<any> & { error: any }>) => {
  const {
    payload,
    error: responseError,
  } = error;

  const {
    response,
  } = payload || {};

  const {
    data,
    status,
  } = response || {};

  const {
    messages,
  } = data?.error || {};

  switch (status) {
    default:
      state.errorTitleKey = 'errors:unableToResend';
      state.message = (messages?.length > 0 && messages[0])
        || responseError?.message
        || 'errors:somethingWentWrong';
      break;
  }
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state: AppError, { payload }): AppError => ({ ...state, ...payload }),
    resetError: (): AppError => (initialState),
  },
  extraReducers: (builder) => {
    // BATCHES
    builder.addCase(fetchAllBatches.rejected, handleError);
    builder.addCase(fetchBatch.rejected, handleError);

    // EMPLOYEES
    builder.addCase(fetchEmployeeConfigs.rejected, handleError);
    builder.addCase(fetchEmployee.rejected, handleError);
    builder.addCase(fetchEmployeeDetailsEWADraws.rejected, handleError);
    builder.addCase(fetchEmployeeDetailsTPODraws.rejected, handleError);
    builder.addCase(fetchEmployeesWithConfigsLocationsAndGroups.rejected, handleError);
    builder.addCase(fetchEmploymentPayrollPeriods.rejected, handleError);
    builder.addCase(fetchWorkerEmploymentLinks.rejected, handleError);
    builder.addCase(fetchFilteredEmployees.rejected, handleError);
    builder.addCase(searchEmployees.rejected, handleError);
    builder.addCase(saveZendeskTicket.rejected, handleError);
    builder.addCase(createDeductionBatches.rejected, handleError);
    builder.addCase(editEmployeeDetailsOnDemandPay.rejected, handleError);
    builder.addCase(editEmployeeDetailsProfile.rejected, handleError);
    builder.addCase(assignEmployeesToEmployment.rejected, handleError);
    builder.addCase(setPrimaryAssignmentForEmployee.rejected, handleError);
    builder.addCase(createACF.rejected, handleError);
    builder.addCase(createBadDebt.rejected, handleError);
    builder.addCase(generateDirectDepositReportReport.rejected, generateDirectDepositReportError);
    builder.addCase(fetchDirectDepositEnrollmentByWorkerId.rejected, fetchDirectDepositEnrollmentByWorkerIdError);
    builder.addCase(fetchEmploymentPayRates.rejected, handleError);
    builder.addCase(editEmploymentPayRates.rejected, handleError);
    builder.addCase(declineDraw.rejected, declineDrawError);
    builder.addCase(voidDraw.rejected, voidDrawError);

    // LOCATIONS
    builder.addCase(fetchLocations.rejected, handleError);
    builder.addCase(fetchLocation.rejected, handleError);
    builder.addCase(fetchLocationConfigs.rejected, handleError);
    builder.addCase(createLocation.rejected, handleError);
    builder.addCase(editLocation.rejected, handleError);
    builder.addCase(editLocationConfigs.rejected, handleError);
    builder.addCase(patchLocation.rejected, handleError);
    builder.addCase(importLocations.rejected, importLocationsError);
    builder.addCase(generateLocationsConfigReport.rejected, handleError);

    // ORGANIZATIONS
    // builder.addCase(fetchAllOrganizations.rejected, handleError);
    builder.addCase(fetchDefaultConfig.rejected, handleError);
    builder.addCase(fetchOrganizationByIdWithConfigAndSectionStatuses.rejected, handleError);
    builder.addCase(fetchOrganizationConfigs.rejected, handleError);
    builder.addCase(createOrganization.rejected, handleError);
    builder.addCase(createOrganizationSectionStatuses.rejected, handleError);
    builder.addCase(editOrganizationSectionStatuses.rejected, handleError);
    builder.addCase(editOrganization.rejected, handleError);
    builder.addCase(editOrganizationConfigs.rejected, handleError);
    builder.addCase(editOrganizationEWASettings.rejected, handleError);
    builder.addCase(editOrganizationTipSourceConfiguration.rejected, handleError);
    builder.addCase(editOrganizationTPOSettings.rejected, saveOrganizationTPOSettingsEWAError);
    builder.addCase(fetchPayGroupsWithLocations.rejected, handleError);
    builder.addCase(saveOrganizationTPOSettingsBanking.rejected, saveOrganizationTPOSettingsBankingError);
    builder.addCase(editOrganizationDirectDepositSettings.rejected, saveOrganizationDirectDepositSettingsEWAError);
    builder.addCase(saveOrganizationDirectDepositSettingsBanking.rejected, saveOrganizationDirectDepositSettingsBankingError);

    // PAY GROUPS
    builder.addCase(fetchPayGroups.rejected, handleError);
    builder.addCase(fetchPayGroup.rejected, handleError);
    builder.addCase(fetchPayrollPeriods.rejected, handleError);
    builder.addCase(fetchPayGroupWithLocations.rejected, handleError);
    builder.addCase(createPayGroup.rejected, handleError);
    builder.addCase(editPayGroup.rejected, handleError);
    builder.addCase(generatePayPeriods.rejected, handleError);
    builder.addCase(savePayGroupPayPeriods.rejected, handleError);
    builder.addCase(assignLocationsToPayGroup.rejected, handleError);
    builder.addCase(closePayPeriod.rejected, handleError);
    builder.addCase(deletePayPeriod.rejected, handleError);
    builder.addCase(updatePayrollPeriod.rejected, handleError);
    builder.addCase(patchPayGroup.rejected, handleError);

    // REPAYMENTS
    builder.addCase(fetchRepayments.rejected, handleError);
    builder.addCase(fetchBatchAllocations.rejected, handleError);
    builder.addCase(fetchBatchEmployments.rejected, handleError);
    builder.addCase(fetchBatchEmploymentDrawAllocations.rejected, handleError);
    builder.addCase(createRepayment.rejected, handleError);
    builder.addCase(updateRepayment.rejected, updateRepaymentError);
    builder.addCase(createTPORepaymentAllocation.rejected, handleError);
    builder.addCase(editRepayment.rejected, fetchOrganizationPaycardSettingsError);
    builder.addCase(saveOrganizationSettings.rejected, saveOrganizationSettingsError);

    // PAYMENT ALLOCATION
    builder.addCase(createDrawAllocations.rejected, handleError);
    builder.addCase(createEmploymentAllocations.rejected, handleError);
    builder.addCase(createBatchAllocations.rejected, handleError);

    // WORKERS
    builder.addCase(fetchWorker.rejected, handleError);
    builder.addCase(fetchWorkers.rejected, handleError);
    builder.addCase(searchWorkers.rejected, handleError);
    builder.addCase(fetchUniversalCalendarDailyShifts.rejected, fetchUniversalCalendarDailyShiftsError);

    // OFFERS
    builder.addCase(fetchEmployeesOffers.rejected, handleError);
    builder.addCase(fetchEmployeeOfferById.rejected, handleError);
    builder.addCase(fetchEmployeesTPOOffers.rejected, fetchEmployeesTPOOffersError);

    // MASTER ACCOUNT
    builder.addCase(fetchBalance.rejected, handleError);
    builder.addCase(fetchDashBalance.rejected, handleError);

    // WORKER ACCOUNTS
    builder.addCase(fetchWorkerAccountExternal.rejected, handleError);
    builder.addCase(fetchWorkerAccountWithBalance.rejected, handleError);
    builder.addCase(fetchWorkerAccountBalance.rejected, handleError);
    builder.addCase(setWorkerProfileAttributesTrusted.rejected, handleError);

    // TRANSACTIONS
    builder.addCase(fetchMasterAccountTransactionsOnLoadMore.rejected, handleError);
    builder.addCase(fetchWorkerAccountTransactionsOnLoadMore.rejected, handleError);
    builder.addCase(fetchMasterAccountTransactions.rejected, handleError);
    builder.addCase(fetchDashAccountTransactions.rejected, handleError);
    builder.addCase(fetchWorkerAccountTransactions.rejected, handleError);
    builder.addCase(fetchBankFiles.rejected, handleError);
    builder.addCase(createTransaction.rejected, handleError);
    builder.addCase(recoverFunds.rejected, handleError);
    builder.addCase(resendMoneyFromMFA.rejected, resendMoneyFromMFAError);

    // ADMINISTRATORS ACCOUNTS
    builder.addCase(saveAdministrator.rejected, handleError);
    builder.addCase(fetchAllAdministrators.rejected, handleError);
    builder.addCase(fetchAdministratorById.rejected, handleError);
    builder.addCase(updateAdminRoles.rejected, handleError);

    // TNA
    builder.addCase(fetchTipsFiles.rejected, handleError);
    builder.addCase(downloadLatestTipFile.rejected, downloadTipsDailyFileError);

    // TPO
    builder.addCase(initiateTPOPayments.rejected, initiateTPOPaymentError);
    builder.addCase(fetchTippedEmployeesByPayPeriod.rejected, handleError);
    builder.addCase(fetchTippedEmployeeBalanceOffer.rejected, handleError);
    builder.addCase(fetchTippedEmployeeBalanceOfferById.rejected, handleError);
    builder.addCase(fetchTippedEmployeeTPOPayments.rejected, handleError);
    builder.addCase(fetchTPOPayrollFile.rejected, handleError);
    builder.addCase(importTipsFile.rejected, importTipsFileError);
    builder.addCase(fetchTPODailyFile.rejected, downloadTipsDailyFileError);

    // REPORTS
    builder.addCase(fetchTreasurySummary.rejected, fetchTreasurySummaryError);
    builder.addCase(fetchOutstandingBatchesReport.rejected, generateDirectDepositReportError);
    builder.addCase(fetchOutstandingRepaymentsReport.rejected, generateDirectDepositReportError);

    // PAYCARDS
    builder.addCase(fetchOrganizationPaycardSettings.rejected, fetchOrganizationPaycardSettingsError);
    builder.addCase(saveOrganizationPaycardSettings.rejected, handleError);
    builder.addCase(fetchPaycardAccountDetails.rejected, handleError);
    builder.addCase(saveLocationPaycardConfig.rejected, handleError);
    builder.addCase(orderNewCards.rejected, orderNewCardsError);

    // BANK DETAILS
    builder.addCase(fetchOrganizationRepaymentRequestsOnLoadMore.rejected, handleError);
    builder.addCase(fetchOrganizationRepaymentRequests.rejected, handleError);
    builder.addCase(processRepaymentRequests.rejected, processPaymentRequestsError);

    // BANK ACCOUNT
    builder.addCase(saveSourceAccount.rejected, sourceAccountsError);
    builder.addCase(deleteSourceAccount.rejected, sourceAccountsError);
    builder.addCase(editSourceAccount.rejected, sourceAccountsError);

    // PAY PERIODS
    builder.addCase(fetchPayPeriodsWithActions.rejected, fetchPayPeriodsWithActionsError);
  },
});

export const { setError, resetError } = errorSlice.actions;
export default errorSlice.reducer;

// Selectors
export const errorSelector = (state: RootState): AppError => state.error;
