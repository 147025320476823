import * as Yup from 'yup';
import {
  EmployeeTypePayRatesType,
  SubElementEWATypeEnum,
} from 'types/EmployeeTypes';
import { commonAmountFormattedValidationSchema } from './commonSchema';

export const EmploymentPayRatesSchema = (employeeType: EmployeeTypePayRatesType) => Yup.object().shape({
  YEARLY: Yup.string().when({
    is: () => employeeType?.[0]?.value === SubElementEWATypeEnum.SALARIED_NOT_CLOCKING,
    then: commonAmountFormattedValidationSchema(),
    otherwise: Yup.string().nullable(),
  }),
  HOURLY: commonAmountFormattedValidationSchema(),
});

export default EmploymentPayRatesSchema;
