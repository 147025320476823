import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPayPeriodsWithActionsRequest, updatePayPeriodActionsRequest } from 'api/payrollPeriodAPI';
import { RootState } from 'store';
import {
  FetchPayPeriodType,
  PayPeriodActionRequiredFilterType,
  PayPeriodStatusFilterType,
  PayPeriodType,
  UpdatePayPeriodActionsType,
} from 'types/PayrollPeriodTypes';
import { OptionalDateOrDateArrayType } from 'types/CommonTypes';

export const initialState = {
  payPeriods: {
    list: [] as PayPeriodType[],
    loading: false,
    failed: false,
    pageNumber: 1,
    pageSize: 10,
    totalSize: 1,
    actionRequired: [] as PayPeriodActionRequiredFilterType[],
    status: [] as PayPeriodStatusFilterType[],
    valueDate: [] as OptionalDateOrDateArrayType,
  },
};

export const fetchPayPeriodsWithActions = createAsyncThunk(
  'dashboard/fetchPayPeriods',
  async (
    params: FetchPayPeriodType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const {
      organizationId,
      pageNumber,
      pageSize,
      to,
      from,
      statuses,
      actionRequired,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await fetchPayPeriodsWithActionsRequest(
        storeState.user.accessToken,
        organizationId,
        pageNumber,
        pageSize,
        from,
        to,
        statuses,
        actionRequired,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePayPeriodActions = createAsyncThunk(
  'dashboard/updatePayPeriodActions',
  async (
    params: UpdatePayPeriodActionsType,
    { getState, rejectWithValue },
  ): Promise<any> => {
    const {
      organizationId, payPeriodId, payGroupId, payload,
    } = params;
    const storeState = getState() as RootState;

    try {
      return await updatePayPeriodActionsRequest(
        storeState.user.accessToken,
        organizationId,
        payPeriodId,
        payGroupId,
        payload,
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    changeActionRequired: (state, action) => {
      state.payPeriods.actionRequired = action.payload;
    },
    setStatus: (state, action) => {
      state.payPeriods.status = action.payload;
    },
    setValueDate: (state, action) => {
      state.payPeriods.valueDate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPayPeriodsWithActions.pending, (state) => {
      state.payPeriods.loading = true;
      state.payPeriods.failed = false;
    });

    builder.addCase(fetchPayPeriodsWithActions.fulfilled, (state, action) => {
      state.payPeriods.list = action.payload.values;
      state.payPeriods.failed = false;
      state.payPeriods.loading = false;
      state.payPeriods.pageNumber = action.payload.pageNumber;
      state.payPeriods.pageSize = action.payload.pageSize;
      state.payPeriods.totalSize = action.payload.totalSize;
    });

    builder.addCase(fetchPayPeriodsWithActions.rejected, (state) => {
      state.payPeriods = {
        ...initialState.payPeriods,
        failed: true,
      };
    });
  },
});

export const {
  changeActionRequired, setStatus, setValueDate,
} = dashboardSlice.actions;

export const payPeriodsListSelector = (state: RootState) => state.dashboard.payPeriods.list;
export const payPeriodsLoadingSelector = (state: RootState) => state.dashboard.payPeriods.loading;
export const payPeriodsFailedSelector = (state: RootState) => state.dashboard.payPeriods.failed;
export const payPeriodsPageNumberSelector = (state: RootState) => state.dashboard.payPeriods.pageNumber;
export const payPeriodsPageSizeSelector = (state: RootState) => state.dashboard.payPeriods.pageSize;
export const payPeriodsTotalSizeSelector = (state: RootState) => state.dashboard.payPeriods.totalSize;
export const payPeriodsNumPagesSelector = (state: RootState) => Math.ceil(
  state.dashboard.payPeriods.totalSize / state.dashboard.payPeriods.pageSize,
);
export const payPeriodsStatusSelector = (state: RootState) => state.dashboard.payPeriods.status;
export const payPeriodsActionRequiredSelector = (state: RootState) => state.dashboard.payPeriods.actionRequired;
export const payPeriodsValueDateSelector = (state: RootState) => state.dashboard.payPeriods.valueDate;

export default dashboardSlice.reducer;
