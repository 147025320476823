import { StyleObject } from 'styletron-react';
import { borderRadius } from 'theme';
import { PayPeriodStatusEnum } from '../../types/PayrollPeriodTypes';

export const containerStyles = {
  width: '100%',
  background: '#F6F6F6',
  minHeight: '100vh',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 100,
};

export const listItemStyles = {
  background: '#FFFFFF',
  padding: '8px',
  borderRadius,
  margin: '16px 0',
};

export const listItemAccordionStyles = {
  PanelContainer: {
    style: {
      margin: '16px 0',
      border: '1px solid #E5E5E5',
      borderRadius: '8px',
    },
  },
  Header: {
    style: {
      borderRadius: '8px',
    },
  },
};

export const skeletonStyles = ({ margin = '' } = {}) => ({
  Root: {
    style: {
      borderRadius,
      margin,
    },
  },
});

export const payPeriodItemContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
} as StyleObject;

export const payPeriodTagContainer = {
  display: 'flex',
  alignItems: 'center',
  margin: '5px 0',
} as StyleObject;

export const isPayPeriodStatusFutureOrOpen = (status: PayPeriodStatusEnum) => status === PayPeriodStatusEnum.FUTURE || status === PayPeriodStatusEnum.OPEN;

export const payPeriodBannerStyles = {
  Root: {
    style: {
      margin: '0',
      cursor: 'normal',
    },
  },
  TrailingIconButton: {
    style: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '22px 16px 0 0',
      cursor: 'pointer',
      ':hover': {
        boxShadow: 'none',
      },
    },
  },
};

export const paragraphLowOpacityStyles = {
  opacity: 0.5,
} as StyleObject;
