import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { setSelectedOfferId } from './offers';

export enum ModalNames {
  REPAYMENT_FORM_MODAL = 'REPAYMENT_FORM_MODAL',
  EMPLOYEE_DRAW_DETAILS_MODAL = 'EMPLOYEE_DRAW_DETAILS_MODAL',
  EMPLOYEE_OFFER_DETAILS_MODAL = 'EMPLOYEE_OFFER_DETAILS_MODAL',
  EMPLOYEE_TPO_OFFER_DETAILS_MODAL = 'EMPLOYEE_TPO_OFFER_DETAILS_MODAL',
  ZENDESK_ADD_MODAL = 'ZENDESK_ADD_MODAL',
  LINK_TO_EMPLOYER_MODAL = 'LINK_TO_EMPLOYER_MODAL',
  LOCATION_FORM_MODAL = 'LOCATION_FORM_MODAL',
  LOCATION_PREVIEW_MODAL = 'LOCATION_PREVIEW_MODAL',
  ASSIGN_LOCATIONS_MODAL = 'ASSIGN_LOCATIONS_MODAL',
  PAY_GROUP_FORM_MODAL = 'PAY_GROUP_FORM_MODAL',
  PAY_PERIOD_MANAGE_MODAL = 'PAY_PERIOD_MANAGE_MODAL',
  PAY_GROUP_PREVIEW_MODAL = 'PAY_GROUP_PREVIEW_MODAL',
  TRANSACTION_HISTORY_MERCHANT_MODAL = 'TRANSACTION_HISTORY_MERCHANT_MODAL',
  TRANSACTION_HISTORY_DETAILS_MODAL = 'TRANSACTION_HISTORY_DETAILS_MODAL',
  CONFIRM_MODAL = 'CONFIRM_MODAL',
  NEW_TRANSACTION_MODAL = 'NEW_TRANSACTION_MODAL',
  WORKER_EMPLOYMENTS_MODAL = 'WORKER_EMPLOYMENTS_MODAL',
  WORKER_LINKED_ACCOUNTS_MODAL = 'WORKER_LINKED_ACCOUNTS_MODAL',
  REPAYMENT_EDIT_MODAL = 'REPAYMENT_EDIT_MODAL',
  RECOVER_FUNDS_MODAL = 'RECOVER_FUNDS_MODAL',
  EDIT_ADMIN_MODAL = 'EDIT_ADMIN_MODAL',
  EMPLOYEE_TPO_PAYMENT_DETAILS_MODAL = 'EMPLOYEE_TPO_PAYMENT_DETAILS_MODAL',
  EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL = 'EMPLOYEE_TPO_OFFER_PER_LOCATION_DETAILS_MODAL',
  ACF_MODAL = 'ACF_MODAL',
  BAD_DEBT_MODAL = 'BAD_DEBT_MODAL',
  EMPLOYEE_BALANCE_DETAILS_MODAL = 'EMPLOYEE_BALANCE_DETAILS_MODAL',
  TPO_REPAYMENT_ALLOCATION_MODAL = 'TPO_REPAYMENT_ALLOCATION_MODAL',
  UPLOAD_TIPS_FILE_MODAL = 'UPLOAD_TIPS_FILE_MODAL',
  GENERATE_DIRECT_DEPOSIT_MODAL = 'GENERATE_DIRECT_DEPOSIT_MODAL',
  WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL = 'WORKER_UNIVERSAL_CALENDAR_SHIFTS_MODAL',
  BANK_ACCOUNT_MODAL = 'BANK_ACCOUNT_MODAL',
  CONFIRM_MODAL_PAYGROUP_EDIT_BANK_ACCOUNT = 'CONFIRM_MODAL_PAYGROUP_EDIT_BANK_ACCOUNT',
  CONFIRM_MODAL_LOCATION_EDIT_BANK_ACCOUNT = 'CONFIRM_MODAL_LOCATION_EDIT_BANK_ACCOUNT',
  CONFIRM_MODAL_ORGANIZATION_DELETE_BANK_ACCOUNT = 'CONFIRM_MODAL_ORGANIZATION_DELETE_BANK_ACCOUNT',
  BANK_ACCOUNT_EDIT_MODAL = 'BANK_ACCOUNT_EDIT_MODAL',
  ORGANIZATION_DESTINATION_ACCOUNT_MODAL = 'ORGANIZATION_DESTINATION_ACCOUNT_MODAL',
  CARD_STOCK_VIEW_HISTORY = 'CARD_STOCK_VIEW_HISTORY',
  UNLOAD_FUNDS_MODAL = 'UNLOAD_FUNDS_MODAL',
  CONFIRM_MODAL_EDIT_PAY_RATES = 'CONFIRM_MODAL_EDIT_PAY_RATES',
  PAYCARD_HELP_AND_SETTINGS = 'PAYCARD_HELP_AND_SETTINGS',
}

export interface Modal {
  name: ModalNames,
  isOpen: boolean,
}

export const initialState: Modal[] = [];

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setModal: (state: Modal[], { payload }): void => {
      const modalIndex = state.findIndex((item) => item.name === payload.name);

      if (modalIndex !== -1) {
        state[modalIndex] = { ...payload };
      } else {
        state.push(payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedOfferId, (state) => (
      [...state.filter((item) => item.name !== ModalNames.EMPLOYEE_OFFER_DETAILS_MODAL), {
        name: ModalNames.EMPLOYEE_OFFER_DETAILS_MODAL,
        isOpen: true,
      }]
    ));
  },
});

export const { setModal } = modalSlice.actions;
export default modalSlice.reducer;

// Selectors
export const modalsSelector = (state: RootState): Modal[] => state.modals;
