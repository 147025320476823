import {
  memo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useStyletron } from 'baseui';
import { useParams } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import { prevPageSelector } from 'store/slices/application';
import {
  fetchDefaultConfig,
  fetchOrganizationByIdWithConfigAndSectionStatuses,
  organizationSelector,
  organizationSectionStatusesSelector,
  editOrganizationSectionStatuses,
  createOrganizationSectionStatuses,
  fetchOrganizationDirectDepositSettingsBanking,
} from 'store/slices/organizations';
import { Block } from 'baseui/block';
import {
  OrganizationSectionNameType,
  OrganizationSectionProgressStatusType,
} from 'types/OrganizationTypes';
import {
  fetchLocations,
  locationsIsListFetchedSelector,
} from 'store/slices/locations';
import {
  fetchPayGroups,
  payGroupsIsListFetchedSelector,
} from 'store/slices/payGroups';
import { fetchPayGroupsWithLocations } from 'store/events';
import findSectionStatusByName from 'utils/findSectionStatusByName';
import OrganizationFormProfileSection from 'screens/Organizations/OrganizationForm//OrganizationFormProfileSection/OrganizationFormProfileSection';
import OrganizationFormPayGroupsSection from 'screens/Organizations/OrganizationForm//OrganizationFormPayGroupsSection/OrganizationFormPayGroupsSection';
import OrganizationFormEWASection from 'screens/Organizations/OrganizationForm//OrganizationFormEWASection/OrganizationFormEWASection';
import OrganizationFormLocationsSection from 'screens/Organizations/OrganizationForm/OrganizationFormLocationsSection/OrganizationFormLocationsSection';
import OrganizationFormTPOSection from 'screens/Organizations/OrganizationForm/OrganizationFormTPOSection/OrganizationFormTPOSection';
import AccordionSection from 'components/AccordionSection/AccordionSection';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import {
  fetchOrganizationPaycardSettings,
  fetchOrganizationsPaycardProvider,
  paycardOrganizationSettingsIsListFetchedSelector,
} from 'store/slices/paycards';
import {
  fetchRepaymentsSourceAccounts,
  repaymentsOrganizationSourceAccountsIsListFetchedSelector,
} from 'store/slices/repaymentsBankDetails';
import {
  contentContainerStyles,
} from './OrganizationFormHelpers';
import OrganizationFormBankDetailsSection from './OrganizationFormBankDetailsSection/OrganizationFormBankDetailsSection';
import OrganizationFormPayrollDirectDepositSection from './OrganizationFormPayrollDirectDepositSection/OrganizationFormPayrollDirectDepositSection';
import OrganizationFormTipSourceConfigurationSection from './OrganizationFormTipSourceConfigurationSection/OrganizationFormTipSourceConfigurationSection';
import OrganizationFormPaycardManagementSection from './OrganizationFormPaymentManagementSection/OrganizationFormPaycardManagementSection';

const OrganizationForm = () => {
  const dispatch = useAppDispatch();
  const [css] = useStyletron();
  const { t } = useTranslation(['organizations', 'errors', 'common', 'locations', 'payGroups']);
  const organization = useAppSelector(organizationSelector);
  const sectionStatuses = useAppSelector(organizationSectionStatusesSelector);
  const arePayGroupsFetched = useAppSelector(payGroupsIsListFetchedSelector);
  const areLocationsFetched = useAppSelector(locationsIsListFetchedSelector);
  const arePaycardSettingsFetched = useAppSelector(paycardOrganizationSettingsIsListFetchedSelector);
  const areBankDetailsFetched = useAppSelector(repaymentsOrganizationSourceAccountsIsListFetchedSelector);
  const prevPage = useAppSelector(prevPageSelector);
  const { id } = organization || {};
  const { organizationID } = useParams<{ organizationID: string }>();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isDisabledDirectDepositSection, setIsDisabledDirectDepositSection] = useState(false);

  const profileSectionStatus = findSectionStatusByName(OrganizationSectionNameType.PROFILE, sectionStatuses);
  const payGroupsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.PAY_GROUP, sectionStatuses);
  const locationsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.PAY_PERIODS, sectionStatuses);
  const EWASettingsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.ORG_DEFAULT_SETTINGS, sectionStatuses);
  const TPOSettingsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.TIP_CASH_OUT, sectionStatuses);
  const DirectDepositSectionStatus = findSectionStatusByName(OrganizationSectionNameType.DIRECT_DEPOSIT, sectionStatuses);
  const PaycardManagementSectionStatus = findSectionStatusByName(OrganizationSectionNameType.PAYCARD_MANAGEMENT, sectionStatuses);
  const bankDetailsSectionStatus = findSectionStatusByName(OrganizationSectionNameType.BANK_DETAILS, sectionStatuses);

  const handleSaveStatus = ({
    sectionID,
    sectionName,
    status,
  }: {
    sectionID?: number
    sectionName: OrganizationSectionNameType
    status: OrganizationSectionProgressStatusType
  }) => {
    if (sectionID) {
      dispatch(editOrganizationSectionStatuses({
        organizationID: id,
        sectionID,
        data: {
          status,
        },
      }));
    } else {
      dispatch(createOrganizationSectionStatuses({
        organizationID: id,
        data: {
          sectionName,
          status,
        },
      }));
    }
  };

  const handleOnLocationsExpand = () => {
    if (organizationID && !areLocationsFetched) {
      dispatch(fetchLocations({ organizationID }));
      dispatch(fetchRepaymentsSourceAccounts({ organizationID }));
    }
  };

  const handleOnPayGroupsExpand = () => {
    if (organizationID && !arePayGroupsFetched) {
      dispatch(fetchPayGroups({ organizationID }));
      dispatch(fetchRepaymentsSourceAccounts({ organizationID }));
    }
  };

  const handleOnPaycardManagementExpand = () => {
    if (organizationID && !arePaycardSettingsFetched) {
      dispatch(fetchOrganizationPaycardSettings({ organizationId: organizationID }));
      dispatch(fetchOrganizationsPaycardProvider());
    }
  };

  const handleOnBankDetailsExpand = () => {
    organizationID && !areBankDetailsFetched && dispatch(fetchRepaymentsSourceAccounts({ organizationID }));
  };

  useEffect(() => {
    dispatch(fetchDefaultConfig());
    setIsFirstLoad(false);
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if ((organizationID || id) && (organizationID?.toString() !== id?.toString())) {
      dispatch(fetchOrganizationByIdWithConfigAndSectionStatuses({ organizationID: organizationID || id }));
      dispatch(fetchOrganizationDirectDepositSettingsBanking({ organizationID: organizationID || id }))
        .unwrap()
        .catch(() => {
          setIsDisabledDirectDepositSection(true);
        });
    }
  }, [organizationID, id, isDisabledDirectDepositSection]);

  useEffect(() => {
    !isFirstLoad && organizationID && dispatch(fetchPayGroupsWithLocations({ organizationID }));
  }, [organizationID]);

  const isStatusToggleDisabled = !organizationID && !organization?.id;

  return (
    <div className={css(contentContainerStyles)}>
      <CommonHeader
        title={organizationID ? `${t('organizations:editOrganization')}: ${organization?.name || ''}` : t('organizations:newOrganization')}
        backTo={`${prevPage || '/organizations'}`}
      />

      <div className={css(contentContainerStyles)}>
        <AccordionSection
          status={profileSectionStatus}
          saveStatus={handleSaveStatus}
          expanded
          title={t('organizations:profile')}
          disabled={false}
          disabledStatus={isStatusToggleDisabled}
        >
          <OrganizationFormProfileSection />
        </AccordionSection>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            status={payGroupsSectionStatus}
            saveStatus={handleSaveStatus}
            title={t('payGroups:payGroups')}
            disabled={!id}
            disabledStatus={isStatusToggleDisabled}
            onExpand={handleOnPayGroupsExpand}
          >
            <OrganizationFormPayGroupsSection />
          </AccordionSection>
        </Block>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            status={locationsSectionStatus}
            saveStatus={handleSaveStatus}
            title={t('locations:locations')}
            disabled={!id}
            disabledStatus={isStatusToggleDisabled}
            onExpand={handleOnLocationsExpand}
          >
            <OrganizationFormLocationsSection />
          </AccordionSection>
        </Block>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            title={t('organizations:tipSourceConfig.section.header')}
            disabled={!id}
            disabledStatus={isStatusToggleDisabled}
            showStatus={false}
          >
            <OrganizationFormTipSourceConfigurationSection />
          </AccordionSection>
        </Block>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            status={EWASettingsSectionStatus}
            saveStatus={handleSaveStatus}
            title={t('organizations:EWASettings')}
            disabled={!id}
            disabledStatus={isStatusToggleDisabled}
          >
            <OrganizationFormEWASection />
          </AccordionSection>
        </Block>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            status={TPOSettingsSectionStatus}
            saveStatus={handleSaveStatus}
            title={t('organizations:TPOSettings')}
            disabled={!id}
            disabledStatus={isStatusToggleDisabled}
          >
            <OrganizationFormTPOSection />
          </AccordionSection>
        </Block>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            status={PaycardManagementSectionStatus}
            title={t('organizations:paymentManagement.section.header')}
            disabledStatus={isStatusToggleDisabled}
            showStatus={false}
            onExpand={handleOnPaycardManagementExpand}
            disabled={!id}
          >
            <OrganizationFormPaycardManagementSection />
          </AccordionSection>
        </Block>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            status={DirectDepositSectionStatus}
            saveStatus={handleSaveStatus}
            title={t('organizations:directDepositSettings')}
            disabled={!id || isDisabledDirectDepositSection}
            disabledStatus={isStatusToggleDisabled}
          >
            <OrganizationFormPayrollDirectDepositSection />
          </AccordionSection>
        </Block>

        <Block
          marginTop="24px"
        >
          <AccordionSection
            status={bankDetailsSectionStatus}
            title={t('organizations:bankDetails.header')}
            disabled={!id}
            showStatus={false}
            disabledStatus={isStatusToggleDisabled}
            onExpand={handleOnBankDetailsExpand}
          >
            <OrganizationFormBankDetailsSection />
          </AccordionSection>
        </Block>
      </div>
    </div>
  );
};

export default memo(OrganizationForm);
