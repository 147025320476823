import { useStyletron } from 'baseui';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CommonHeader from 'components/CommonHeader/CommonHeader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeActionRequired, fetchPayPeriodsWithActions, payPeriodsLoadingSelector, setStatus, setValueDate,
} from 'store/slices/dashboard';
import { loggedOrganizationSelector } from 'store/slices/loggedOrganization';
import { Grid, ALIGNMENT, Cell } from 'baseui/layout-grid';
import { HeadingSmall } from 'baseui/typography';
import { Button, KIND } from 'baseui/button';
import { SIZE } from 'baseui/input';
import { StyleObject } from 'styletron-react';
import Loader from 'components/Loader';
import { containerStyles } from './DashboardHelper';
import PayPeriodStatusFilters from './PayPeriodStatusFilters/PayPeriodStatusFilters';
import PayPeriodsSection from './PayPeriodsSection/PayPeriodsSection';

const Dashboard = () => {
  const [css] = useStyletron();
  const { t } = useTranslation(['common', 'dashboard']);
  const organization = useAppSelector(loggedOrganizationSelector);
  const loading = useAppSelector(payPeriodsLoadingSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    organization
      && dispatch(fetchPayPeriodsWithActions({
        organizationId: organization.id,
        pageNumber: 1,
        pageSize: 10,
      }));

    return () => {
      dispatch(setValueDate([]));
      dispatch(setStatus([]));
      dispatch(changeActionRequired([]));
    };
  }, []);

  return (
    <div className={css(containerStyles as StyleObject)}>
      <Loader active={loading} />
      <CommonHeader title={t('dashboard:dashboard.pageHeader')}>
        <Button
          kind={KIND.secondary}
          onClick={() => {}}
          size={SIZE.compact}
        >
          {t('dashboard:dashboard.exportButton')}
        </Button>
      </CommonHeader>
      <Grid
        align={ALIGNMENT.end}
        gridColumns={12}
      >
        <Cell
          span={12}
          align={ALIGNMENT.start}
        >
          <HeadingSmall margin="24px 0">
            {t('dashboard:dashboard.payPeriodStatusHeader')}
          </HeadingSmall>
        </Cell>
      </Grid>
      {!loading && <PayPeriodStatusFilters />}
      {!loading && <PayPeriodsSection />}
    </div>
  );
};

export default memo(Dashboard);
