import { Block } from 'baseui/block';
import { ALIGNMENT, Cell, Grid } from 'baseui/layout-grid';
import { Pagination, SIZE } from 'baseui/pagination';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  drawsListContainerStyles, tableHeaderStyles, tableStyles,
} from 'screens/Employees/EmployeesHelpers';
import { useStyletron } from 'styletron-react';
import { unScalePrice } from 'utils/priceScale';
import {
  Draw,
  EmployeeDrawsAvailableOperations,
  EmployeeIDType,
  SubElementPaymentMethodEnum,
  SubElementType,
  SubElementTypeEnum,
} from 'types/EmployeeTypes';
import moment from 'moment';
import priceFormatter from 'utils/priceFormatter';
import { emptyPlaceholder } from 'theme';
import { paginationTransparentOverrides } from 'screens/CommonHelpers';
import Loader from 'components/Loader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  declineDraw, employeesEWADrawsPendingListSelector, fetchEmployeeOfferById, voidDraw,
} from 'store/slices/employees';
import { ModalNames, modalsSelector, setModal } from 'store/slices/modals';
import { setSelectedOfferId } from 'store/slices/offers';
import checkIsModalOpen from 'utils/checkIsModalOpen';
import ZendeskModal from 'screens/Employees/ZendeskModal';
import { resendMoneyFromMFA } from 'store/slices/transactions';
import EmployeeDetailsDrawDetailsModal from './EmployeeDetailsDrawDetailsModal';
import InitiateACFModal from './InitiateACFModal';
import { calculateFees, extractAmountItem, extractFeesItems } from './EmployeeDetailsDrawSection';
import EmployeeDetailsDrawListItem from './EmployeeDetailsDrawListItem';
import InitiateBadDebtModal from './InitiateBadDebtModal';

export const feeTypes = [
  SubElementTypeEnum.DRAW_FEE_WORKER,
  SubElementTypeEnum.DRAW_FEE_EMPLOYER,
  SubElementTypeEnum.SAME_DAY_ACH_TRANSFER_FEE,
  SubElementTypeEnum.PUSH_TO_CARD_TRANSFER_FEE,
];

export const paymentMethodTypes = [
  SubElementPaymentMethodEnum.FUEGO_CARD,
  SubElementPaymentMethodEnum.ACH_TRANSFER,
  SubElementPaymentMethodEnum.ACH_TRANSFER_SAME_DAY,
  SubElementPaymentMethodEnum.PUSH_TO_EXTERNAL_CARD,
  SubElementPaymentMethodEnum.FUEGO_PAYCARD,
];

export type EmployeeDetailsDrawTablePropsType = {
  filteredDraws: Draw[],
  employeeID: EmployeeIDType,
  handlePageChange: ({ nextPage }: { nextPage: number }) => void,
  pageNumber: number,
  numPages: number,
  search: string,
  fetchDraws: (filter: any) => void,
}

const EmployeeDetailsDrawTable = ({
  filteredDraws,
  employeeID,
  handlePageChange,
  pageNumber,
  numPages,
  search,
  fetchDraws,
}: EmployeeDetailsDrawTablePropsType) => {
  const [css] = useStyletron();
  const { t } = useTranslation(['employees', 'dateFormats']);
  const dateFormat = t('dateFormats:standard');
  const pending = useAppSelector(employeesEWADrawsPendingListSelector);
  const dispatch = useAppDispatch();
  const modals = useAppSelector(modalsSelector);
  const [clickedDraw, setClickedDraw] = useState<Draw>();
  const [maximumAmount, setMaximumAmount] = useState<number>(0);
  const [clickedPaymentId, setClickedPaymentId] = useState<string>();

  const calculateFeesExcludingVoidTransactions = (feesItems: SubElementType[]) => feesItems
    ?.reduce((acc, subElement) => +acc + (subElement?.status !== 'VOIDED' ? unScalePrice(subElement?.amount?.value, subElement?.amount?.scale) : 0), 0);

  const payPeriodFormat: any = (startDate: any, endDate: any) => `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`;

  const handleAddZendeskBtnClick = (
    selectedDraw: Draw,
  ) => {
    setClickedDraw(selectedDraw);
    dispatch(setModal({
      name: ModalNames.ZENDESK_ADD_MODAL,
      isOpen: true,
    }));
  };
  const handleInitiateACFBtnClick = (
    selectedDraw: Draw,
    maxAmount: number,
  ) => {
    setClickedDraw(selectedDraw);
    setMaximumAmount(maxAmount);
    dispatch(setModal({
      name: ModalNames.ACF_MODAL,
      isOpen: true,
    }));
  };
  const handleInitiateBadDebtBtnClick = (
    selectedDraw: Draw,
    maxAmount: number,
  ) => {
    setClickedDraw(selectedDraw);
    setMaximumAmount(maxAmount);
    dispatch(setModal({
      name: ModalNames.BAD_DEBT_MODAL,
      isOpen: true,
    }));
  };
  const handleAvailableOperationClick = (
    selectedDraw: Draw,
    type: EmployeeDrawsAvailableOperations,
  ) => {
    setClickedPaymentId(`${selectedDraw?.id}`);
    switch (type) {
      case EmployeeDrawsAvailableOperations.VOID:
        dispatch(voidDraw(({
          employmentID: employeeID,
          ledgerID: `${selectedDraw?.id}`,
        }))).then(() => {
          setClickedPaymentId('');
          fetchDraws({ pageNumber: '1', type: 'EWA' });
        });
        break;
      case EmployeeDrawsAvailableOperations.RETRY:
        dispatch(resendMoneyFromMFA(({
          workerID: selectedDraw?.workerId,
          data: {
            ledgerId: `${selectedDraw?.id}`,
            paymentMethod: selectedDraw?.paymentMethod,
            accountId: selectedDraw?.recipientAccountId || '',
          },
        }))).then(() => {
          setClickedPaymentId('');
          fetchDraws({ pageNumber: '1', type: 'EWA' });
        });
        break;
      case EmployeeDrawsAvailableOperations.DECLINE:
        dispatch(declineDraw(({
          employmentID: employeeID,
          ledgerID: `${selectedDraw?.id}`,
        }))).then(() => {
          setClickedPaymentId('');
          fetchDraws({ pageNumber: '1', type: 'EWA' });
        });
        break;

      default:
        break;
    }
  };

  const handleDrawIDClick = (selectedDraw: Draw) => {
    setClickedDraw(selectedDraw);
    dispatch(setModal({
      name: ModalNames.EMPLOYEE_DRAW_DETAILS_MODAL,
      isOpen: true,
    }));
  };

  const handleOfferIDClick = (selectedDraw: Draw) => {
    setClickedDraw(selectedDraw);
    dispatch(fetchEmployeeOfferById({ offerID: String(selectedDraw?.offerId) }));
    dispatch(setSelectedOfferId(selectedDraw.offerId));
  };

  return (
    <>
      <div className={css(tableStyles)}>
        <div className={css(tableHeaderStyles)}>
          <Grid
            gridColumns={12}
            align={ALIGNMENT.center}
            gridMargins={30}
          >
            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <Block display="flex" justifyContent="start">
                <strong>{t('employees:table.requestDate')}</strong>
              </Block>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.drawID')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.offerID')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.payPeriod')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.drawAmount')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.fees')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.depositMethod')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.acctName')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.status')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.payPeriodTotal')}</strong>
            </Cell>

            <Cell
              align={ALIGNMENT.center}
              span={1}
            >
              <strong>{t('employees:table.zendeskRef')}</strong>
            </Cell>
          </Grid>
        </div>

        <div className={css(drawsListContainerStyles)}>
          <Loader active={pending} />
          {filteredDraws
            ?.map((draw) => {
              const amountItem: SubElementType | undefined = extractAmountItem(draw?.subElements);
              const paymentMethod: SubElementPaymentMethodEnum | undefined = paymentMethodTypes
                ?.find((paymentMethodType) => paymentMethodType === draw?.paymentMethod);
              const feesItems: SubElementType[] = extractFeesItems(draw?.subElements);
              const amount = amountItem?.amount ? unScalePrice(amountItem?.amount?.value, amountItem?.amount?.scale) : 0;
              const amountForPayperiodTotal = amountItem?.amount && amountItem?.status !== 'VOIDED'
                ? unScalePrice(amountItem?.amount?.value, amountItem?.amount?.scale) : 0;
              const status = amountItem?.status || emptyPlaceholder;
              const recipient = amountItem?.recipient;
              const fees = calculateFees(feesItems);
              const feesForPayPeriodTotal = calculateFeesExcludingVoidTransactions(feesItems);
              const zendeskTicketRef = draw?.zendeskTicket ? `${draw?.zendeskTicket?.id} (${draw?.zendeskTicket?.status})` : emptyPlaceholder;
              const payPeriod = payPeriodFormat(draw?.payrollPeriod?.startDate, draw?.payrollPeriod?.endDate);

              const previousDrawsTotal: number = filteredDraws
                .filter((prevDraw) => moment(prevDraw?.payrollPeriod?.startDate)
                  .format(dateFormat) === moment(draw?.payrollPeriod?.startDate).format(dateFormat)
                  && moment(prevDraw?.payrollPeriod?.endDate).format(dateFormat) === moment(draw?.payrollPeriod?.endDate).format(dateFormat)
                  && (moment(draw?.drawRequestedDate).unix()) > (moment(prevDraw?.drawRequestedDate).unix()))
                .reduce((acc: number, prevDraw: Draw) => {
                  const prevDrawAmontItem: SubElementType | undefined = extractAmountItem(prevDraw?.subElements);
                  const prevDrawFees: SubElementType[] = extractFeesItems(prevDraw?.subElements);
                  const hasPrevAmount = prevDrawAmontItem?.amount && prevDrawAmontItem?.status !== 'VOIDED';
                  const prevDrawAmount = hasPrevAmount ? unScalePrice(prevDrawAmontItem?.amount?.value, amountItem?.amount?.scale) : 0;
                  const prevDrawFeesTotal = calculateFeesExcludingVoidTransactions(prevDrawFees);

                  return acc + (prevDrawAmount + prevDrawFeesTotal);
                }, 0);
              const payPeriodTotal = priceFormatter().format(amountForPayperiodTotal + feesForPayPeriodTotal + previousDrawsTotal);
              return (
                <EmployeeDetailsDrawListItem
                  key={draw.id}
                  search={search}
                  draw={draw}
                  handleAddZendeskBtnClick={handleAddZendeskBtnClick}
                  handleInitiateACFBtnClick={handleInitiateACFBtnClick}
                  handleInitiateBadDebtBtnClick={handleInitiateBadDebtBtnClick}
                  handleAvailableOperationClick={handleAvailableOperationClick}
                  handleDrawIDClick={handleDrawIDClick}
                  amount={amount}
                  status={status}
                  recipient={recipient}
                  fees={fees}
                  zendeskTicketRef={zendeskTicketRef}
                  payPeriod={payPeriod}
                  paymentMethod={paymentMethod}
                  handleOfferIDClick={handleOfferIDClick}
                  payPeriodTotal={payPeriodTotal}
                  employeeID={employeeID}
                  clickedPaymentId={clickedPaymentId}
                />
              );
            })}
          {filteredDraws.length === 0 && (
            <Block
              margin="24px"
            >
              <Grid
                gridColumns={12}
                align={ALIGNMENT.center}
                gridMargins={4}
              >
                <Cell
                  align={ALIGNMENT.center}
                  span={2}
                >
                  {t('employees:noDrawsAvailable')}
                </Cell>
              </Grid>
            </Block>
          )}
        </div>
        {!search && filteredDraws.length !== 0 && (
          <Block
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            marginBottom="16px"
          >
            <Pagination
              size={SIZE.compact}
              numPages={numPages}
              currentPage={pageNumber}
              overrides={paginationTransparentOverrides}
              onPageChange={handlePageChange}
            />
          </Block>
        )}
      </div>
      {checkIsModalOpen(modals, ModalNames.ZENDESK_ADD_MODAL) && <ZendeskModal drawID={clickedDraw?.id} zendeskTicket={clickedDraw?.zendeskTicket} />}
      {checkIsModalOpen(modals, ModalNames.EMPLOYEE_DRAW_DETAILS_MODAL) && <EmployeeDetailsDrawDetailsModal drawID={clickedDraw?.id} />}
      {checkIsModalOpen(modals, ModalNames.ACF_MODAL) && <InitiateACFModal draw={clickedDraw} maxAmount={maximumAmount} />}
      {checkIsModalOpen(modals, ModalNames.BAD_DEBT_MODAL) && <InitiateBadDebtModal draw={clickedDraw} maxAmount={maximumAmount} />}
    </>
  );
};

export default memo(EmployeeDetailsDrawTable);
