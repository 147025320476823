export type StatusItemType = {
  value: TransactionsStatus
  label: string
}

export type TypesItemType = {
  value: TransactionsType
  label: string
}

export type DashTypesItemType = {
  value: PaycardTrasanctionsType
  label: string
}

export interface BalanceType {
  currencyCode: string,
  availableBalance: number,
  ledgerBalance: number
}

export type TransactionsFilterType = {
  page: string,
  transactionStatus?: TransactionsStatus | '',
  transactionType?: string | '',
  transactionNumber?: string,
  fromDate?: string,
  toDate?: string,
}

export enum TransactionsStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
}

export enum TransactionsType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  BANK_SAME_DAY_TRANSFER_DEBIT = 'BANK_SAME_DAY_TRANSFER_DEBIT',
  NETWORK_TRANSFER_DEBIT = 'NETWORK_TRANSFER_DEBIT',
}

export enum PaycardTrasanctionsType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
  UNKNOWN = 'UNKNOWN',
}

export const transactionStatusTypes = [
  TransactionsStatus.PENDING,
  TransactionsStatus.COMPLETED,
  TransactionsStatus.DECLINED,
  TransactionsStatus.EXPIRED,
];

export enum TransactionOrigin {
  UNKNOWN = 'UNKNOWN',
  DRAW_TO_BANK_ACCOUNT = 'DRAW_TO_BANK_ACCOUNT',
  DRAW_TO_EXTERNAL_CARD = 'DRAW_TO_EXTERNAL_CARD',
  DRAW_TO_FUEGO_CARD = 'DRAW_TO_FUEGO_CARD',
  FUEGO_CARD_TRANSFER_TO_BANK_ACCOUNT = 'FUEGO_CARD_TRANSFER_TO_BANK_ACCOUNT',
  FUEGO_CARD_TRANSFER_TO_EXTERNAL_CARD = 'FUEGO_CARD_TRANSFER_TO_EXTERNAL_CARD',
  FUEGO_CARD_TRANSFER_TO_FUEGO_CARD = 'FUEGO_CARD_TRANSFER_TO_FUEGO_CARD',
  PFM_PAYMENT = 'PFM_PAYMENT',
  TCO_PAYMENT_TO_FUEGO_CARD = 'PFM_PAYMENT, TCO_PAYMENT_TO_FUEGO_CARD',
  TCO_PAYMENT_TO_BANK_ACCOUNT = 'TCO_PAYMENT_TO_BANK_ACCOUNT',
  TCO_PAYMENT_TO_EXTERNAL_CARD = 'TCO_PAYMENT_TO_EXTERNAL_CARD',
  MANUAL_RECOVERY = 'MANUAL_RECOVERY',
  FAILED_DRAW_AUTO_RECOVERY = 'FAILED_DRAW_AUTO_RECOVERY',
  FAILED_TPO_AUTO_RECOVERY = 'FAILED_TPO_AUTO_RECOVERY',
  REFUND = 'REFUND'
}

export enum TransactionSourceType {
  UNKNOWN = 'UNKNOWN',
  MASTER_FUNDING_ACCOUNT = 'MASTER_FUNDING_ACCOUNT',
  SET_ASIDE_ACCOUNT = 'SET_ASIDE_ACCOUNT',
  FOR_BENEFIT_OF_ACCOUNT = 'FOR_BENEFIT_OF_ACCOUNT',
  FUEGO_CARD = 'FUEGO_CARD',
  EXTERNAL = 'EXTERNAL'
}
export type MerchantType = {
  mcc: number,
  mccName: string | null,
  mccGroup: string | null,
  name: string | null,
  city: string | null,
  region: string | null,
  country: string | null,
}

export type MasterTransactionsValueType = {
  workerId?: string,
  id?: string,
  type?: string,
  status?: TransactionsStatus,
  amount: number,
  currencyCode?: string,
  merchant?: MerchantType,
  transactionNumber?: string,
  transactionDate?: string,
  origin?: TransactionOrigin,
  ledgerId?: string,
  subElementId?: string,
  description?: string
}

export type MerchantModalPropsType = {
  merchant?: MerchantType
}

export type MasterAccountTransactionsDetailsModalPropsType = {
  transaction?: MasterTransactionsValueType
}

export type FetchMasterAccountTransactionsPropsType = {
  startDate: string
  endDate: string
  transactionStatus?: TransactionsStatus
  transactionType?: string
  transactionNumber?: string
}

export type DashTransactionsValueType = {
  proxyNumber?: string,
  transactionId?: string,
  transactionDate?: string,
  datePosted?: string,
  amount: number,
  currencyCode?: string,
  description?: string,
  additionalInfo?: string,
  type: PaycardTrasanctionsType,
}

export type FetchDashAccountTransactionsPropsType = {
  startDate: string,
  endDate: string,
  proxyNumber?: string,
  transactionType?: PaycardTrasanctionsType,
}
